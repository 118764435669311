var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('b-container',{attrs:{"fluid":""}},[_c('b-row',[_c('b-col',{attrs:{"lg":"12","sm":"12"}},[_c('b-overlay',{attrs:{"show":_vm.loading}},[_c('b-row',[_c('b-col',{attrs:{"lg":"12","sm":"12"}},[_c('CCard',[_c('CCardHeader',[_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-md-6"},[_c('strong',[_vm._v("Main Setting")])])])]),_c('CCardBody',[_c('div',[_c('b-col',{attrs:{"lg":"12","sm":"12"}},[_c('b-overlay',{attrs:{"show":false}},[_c('ValidationObserver',{ref:"form",scopedSlots:_vm._u([{key:"default",fn:function(ref){
var handleSubmit = ref.handleSubmit;
var reset = ref.reset;
return [_c('b-form',{on:{"submit":function($event){$event.preventDefault();return handleSubmit(_vm.register)},"reset":function($event){$event.preventDefault();return reset.apply(null, arguments)}}},[_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-md-6"},[_c('ValidationProvider',{attrs:{"name":"Refer Bonus (Point)","vid":"refer_bonus","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var valid = ref.valid;
var errors = ref.errors;
return _c('b-form-group',{staticClass:"row",attrs:{"label-cols-sm":"12","label-for":"formData"},scopedSlots:_vm._u([{key:"label",fn:function(){return [_vm._v(" Refer Bonus (Point) "),_c('span',{staticClass:"text-danger"},[_vm._v("*")])]},proxy:true}],null,true)},[_c('b-form-input',{attrs:{"type":"text","id":"refer_bonus","state":errors[0] ? false : (valid ? true : null)},model:{value:(_vm.formData.refer_bonus),callback:function ($$v) {_vm.$set(_vm.formData, "refer_bonus", $$v)},expression:"formData.refer_bonus"}}),_c('div',{staticClass:"invalid-feedback"},[_vm._v(" "+_vm._s(errors[0])+" ")])],1)}}],null,true)})],1),_c('div',{staticClass:"col-md-6"},[_c('ValidationProvider',{attrs:{"name":"Convert Dollar","vid":"convert_dollar","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var valid = ref.valid;
var errors = ref.errors;
return _c('b-form-group',{staticClass:"row",attrs:{"label-cols-sm":"12","label-for":"formData"},scopedSlots:_vm._u([{key:"label",fn:function(){return [_vm._v(" Convert Point To Dollar "),_c('span',{staticClass:"text-danger"},[_vm._v("*")])]},proxy:true}],null,true)},[_c('b-form-input',{attrs:{"type":"text","id":"convert_dollar","state":errors[0] ? false : (valid ? true : null)},model:{value:(_vm.formData.convert_dollar),callback:function ($$v) {_vm.$set(_vm.formData, "convert_dollar", $$v)},expression:"formData.convert_dollar"}}),_c('div',{staticClass:"invalid-feedback"},[_vm._v(" "+_vm._s(errors[0])+" ")])],1)}}],null,true)})],1),_c('div',{staticClass:"col-md-6"},[_c('ValidationProvider',{attrs:{"name":"Install Bonus","vid":"install_bonus","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var valid = ref.valid;
var errors = ref.errors;
return _c('b-form-group',{staticClass:"row",attrs:{"label-cols-sm":"12","label-for":"install_bonus"},scopedSlots:_vm._u([{key:"label",fn:function(){return [_vm._v(" Install Bonus Dollar "),_c('span',{staticClass:"text-danger"},[_vm._v("*")])]},proxy:true}],null,true)},[_c('b-form-input',{attrs:{"type":"text","id":"install_bonus","state":errors[0] ? false : (valid ? true : null)},model:{value:(_vm.formData.install_bonus),callback:function ($$v) {_vm.$set(_vm.formData, "install_bonus", $$v)},expression:"formData.install_bonus"}}),_c('div',{staticClass:"invalid-feedback"},[_vm._v(" "+_vm._s(errors[0])+" ")])],1)}}],null,true)})],1),_c('div',{staticClass:"col-md-6"},[_c('ValidationProvider',{attrs:{"name":"Task Hour","vid":"task_hour","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var valid = ref.valid;
var errors = ref.errors;
return _c('b-form-group',{staticClass:"row",attrs:{"label-cols-sm":"12","label-for":"task_hour"},scopedSlots:_vm._u([{key:"label",fn:function(){return [_vm._v(" Task Hour "),_c('span',{staticClass:"text-danger"},[_vm._v("*")])]},proxy:true}],null,true)},[_c('b-form-input',{attrs:{"type":"text","id":"task_hour","state":errors[0] ? false : (valid ? true : null)},model:{value:(_vm.formData.task_hour),callback:function ($$v) {_vm.$set(_vm.formData, "task_hour", $$v)},expression:"formData.task_hour"}}),_c('div',{staticClass:"invalid-feedback"},[_vm._v(" "+_vm._s(errors[0])+" ")])],1)}}],null,true)})],1),_c('div',{staticClass:"col-md-6"},[_c('ValidationProvider',{attrs:{"name":"Task Point","vid":"task_point","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var valid = ref.valid;
var errors = ref.errors;
return _c('b-form-group',{staticClass:"row",attrs:{"label-cols-sm":"12","label-for":"task_point"},scopedSlots:_vm._u([{key:"label",fn:function(){return [_vm._v(" Task Point "),_c('span',{staticClass:"text-danger"},[_vm._v("*")])]},proxy:true}],null,true)},[_c('b-form-input',{attrs:{"type":"text","id":"task_point","state":errors[0] ? false : (valid ? true : null)},model:{value:(_vm.formData.task_point),callback:function ($$v) {_vm.$set(_vm.formData, "task_point", $$v)},expression:"formData.task_point"}}),_c('div',{staticClass:"invalid-feedback"},[_vm._v(" "+_vm._s(errors[0])+" ")])],1)}}],null,true)})],1),_c('div',{staticClass:"col-md-6"},[_c('ValidationProvider',{attrs:{"name":"Video Task Hour","vid":"vdeo_task_hour","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var valid = ref.valid;
var errors = ref.errors;
return _c('b-form-group',{staticClass:"row",attrs:{"label-cols-sm":"12","label-for":"vdeo_task_hour"},scopedSlots:_vm._u([{key:"label",fn:function(){return [_vm._v(" Vdeo Task Hour "),_c('span',{staticClass:"text-danger"},[_vm._v("*")])]},proxy:true}],null,true)},[_c('b-form-input',{attrs:{"type":"text","id":"vdeo_task_hour","state":errors[0] ? false : (valid ? true : null)},model:{value:(_vm.formData.vdeo_task_hour),callback:function ($$v) {_vm.$set(_vm.formData, "vdeo_task_hour", $$v)},expression:"formData.vdeo_task_hour"}}),_c('div',{staticClass:"invalid-feedback"},[_vm._v(" "+_vm._s(errors[0])+" ")])],1)}}],null,true)})],1),_c('div',{staticClass:"col-md-6"},[_c('ValidationProvider',{attrs:{"name":"Telegram Point","vid":"telegram_point","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var valid = ref.valid;
var errors = ref.errors;
return _c('b-form-group',{staticClass:"row",attrs:{"label-cols-sm":"12","label-for":"telegram_point"},scopedSlots:_vm._u([{key:"label",fn:function(){return [_vm._v(" Telegram Point "),_c('span',{staticClass:"text-danger"},[_vm._v("*")])]},proxy:true}],null,true)},[_c('b-form-input',{attrs:{"type":"text","id":"telegram_point","state":errors[0] ? false : (valid ? true : null)},model:{value:(_vm.formData.telegram_point),callback:function ($$v) {_vm.$set(_vm.formData, "telegram_point", $$v)},expression:"formData.telegram_point"}}),_c('div',{staticClass:"invalid-feedback"},[_vm._v(" "+_vm._s(errors[0])+" ")])],1)}}],null,true)})],1),_c('div',{staticClass:"col-md-6"},[_c('ValidationProvider',{attrs:{"name":"Speed Point","vid":"speed_point","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var valid = ref.valid;
var errors = ref.errors;
return _c('b-form-group',{staticClass:"row",attrs:{"label-cols-sm":"12","label-for":"speed_point"},scopedSlots:_vm._u([{key:"label",fn:function(){return [_vm._v(" Speed Point "),_c('span',{staticClass:"text-danger"},[_vm._v("*")])]},proxy:true}],null,true)},[_c('b-form-input',{attrs:{"type":"text","id":"speed_point","state":errors[0] ? false : (valid ? true : null)},model:{value:(_vm.formData.speed_point),callback:function ($$v) {_vm.$set(_vm.formData, "speed_point", $$v)},expression:"formData.speed_point"}}),_c('div',{staticClass:"invalid-feedback"},[_vm._v(" "+_vm._s(errors[0])+" ")])],1)}}],null,true)})],1),_c('div',{staticClass:"col-md-6"},[_c('ValidationProvider',{attrs:{"name":"Speed Limit","vid":"speed_limit","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var valid = ref.valid;
var errors = ref.errors;
return _c('b-form-group',{staticClass:"row",attrs:{"label-cols-sm":"12","label-for":"speed_limit"},scopedSlots:_vm._u([{key:"label",fn:function(){return [_vm._v(" Speed Limit "),_c('span',{staticClass:"text-danger"},[_vm._v("*")])]},proxy:true}],null,true)},[_c('b-form-input',{attrs:{"type":"text","id":"speed_limit","state":errors[0] ? false : (valid ? true : null)},model:{value:(_vm.formData.speed_limit),callback:function ($$v) {_vm.$set(_vm.formData, "speed_limit", $$v)},expression:"formData.speed_limit"}}),_c('div',{staticClass:"invalid-feedback"},[_vm._v(" "+_vm._s(errors[0])+" ")])],1)}}],null,true)})],1),_c('div',{staticClass:"col-md-12"},[_c('h4',[_vm._v("Notification Setting")])]),_c('div',{staticClass:"col-md-6"},[_c('ValidationProvider',{attrs:{"name":"One Signal App ID","vid":"one_signal_app_id","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var valid = ref.valid;
var errors = ref.errors;
return _c('b-form-group',{staticClass:"row",attrs:{"label-cols-sm":"12","label-for":"one_signal_app_id"},scopedSlots:_vm._u([{key:"label",fn:function(){return [_vm._v(" One Signal App ID "),_c('span',{staticClass:"text-danger"},[_vm._v("*")])]},proxy:true}],null,true)},[_c('b-form-input',{attrs:{"type":"text","id":"one_signal_app_id","state":errors[0] ? false : (valid ? true : null)},model:{value:(_vm.formData.one_signal_app_id),callback:function ($$v) {_vm.$set(_vm.formData, "one_signal_app_id", $$v)},expression:"formData.one_signal_app_id"}}),_c('div',{staticClass:"invalid-feedback"},[_vm._v(" "+_vm._s(errors[0])+" ")])],1)}}],null,true)})],1),_c('div',{staticClass:"col-md-6"},[_c('ValidationProvider',{attrs:{"name":"One Signal Api Key","vid":"one_signal_api_key","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var valid = ref.valid;
var errors = ref.errors;
return _c('b-form-group',{staticClass:"row",attrs:{"label-cols-sm":"12","label-for":"one_signal_api_key"},scopedSlots:_vm._u([{key:"label",fn:function(){return [_vm._v(" One Signal Api Key "),_c('span',{staticClass:"text-danger"},[_vm._v("*")])]},proxy:true}],null,true)},[_c('b-form-input',{attrs:{"type":"text","id":"one_signal_api_key","state":errors[0] ? false : (valid ? true : null)},model:{value:(_vm.formData.one_signal_api_key),callback:function ($$v) {_vm.$set(_vm.formData, "one_signal_api_key", $$v)},expression:"formData.one_signal_api_key"}}),_c('div',{staticClass:"invalid-feedback"},[_vm._v(" "+_vm._s(errors[0])+" ")])],1)}}],null,true)})],1),_c('div',{staticClass:"col-md-12 text-right"},[_c('b-button',{staticClass:"mr-2",staticStyle:{"margin-top":"40px"},attrs:{"type":"submit","variant":"primary"}},[_vm._v(_vm._s(_vm.saveBtnName))])],1)])])]}}])})],1)],1)],1)])],1)],1)],1)],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }